import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IAppointment } from "@/model/appointment.model";
import { APPOINTMENT_NAMESPACE } from "@/_store/appointment";
import { Getter, Action, namespace } from "vuex-class";
import moment, { Moment } from "moment";
import { IConfigAppointmentModule } from "@/_store/types";
import {
  bindEvent,
  stringValidJson,
  sendMessage,
  removeEvent,
  getBoolean,
  retry,
} from "@/_utils";
import { deepClone } from "fast-json-patch";
import "./styles.scss";
const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component
export default class CalendarComponent extends Vue {
  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;
  @appointmentModule.Getter getError!: { message: string; times: number };
  @appointmentModule.Action storeError!: (payload: {
    message: string;
    times: number;
  }) => void;

  @Action("syncAppointmentClick", { namespace: APPOINTMENT_NAMESPACE })
  private syncAppointmentClick!: (criteria: { id: number }) => void;

  @Action("getAppointmentByObjectId", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentByObjectId!: (criteria: {
    objectId: string;
    key: string;
    IsProject: boolean;
  }) => Promise<void>;

  @Action("checkAppointmentExists", { namespace: APPOINTMENT_NAMESPACE })
  private checkAppointmentExists!: (id: number) => Promise<boolean>;

  @Getter("getAppointments", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointments!: IAppointment[];

  @Getter("getStatusLoadingAppointments", { namespace: APPOINTMENT_NAMESPACE })
  private getStatusLoadingAppointments!: boolean;

  public currentView = "month";
  public datePick: string = "";

  get isProject() {
    return getBoolean(this.getConfig.isProject);
  }

  get showAllAppointment() {
    if (
      this.getConfig.showAllAppointment &&
      this.getConfig.showAllAppointment == "yes"
    ) {
      return true;
    }
    return false;
  }

  get coLoading() {
    return this.getStatusLoadingAppointments || false;
  }

  get buttonBackColor() {
    return "#" + this.getConfig.buttonBackColor || "";
  }

  get buttonFontColor() {
    return "#" + this.getConfig.buttonFontColor || "";
  }

  get coDayPicked() {
    return this.datePick;
  }

  get coDayPickedfFormat() {
    if (this.datePick !== "") {
      if (this.$i18n.locale === "fr" || this.$i18n.locale === "nl")
        // https://jira.omnicasa.com/browse/OSW-394
        return moment(this.datePick)
          .locale(this.$i18n.locale)
          .format("dddd DD MMMM YYYY");
      return moment(this.datePick)
        .locale(this.$i18n.locale)
        .format("dddd MMMM Do YYYY");
    }
    return "";
  }

  get coLocale() {
    return this.$i18n.locale || "en";
  }

  get coEvents() {
    const events = [];
    const appointments = deepClone(this.getAppointments).sort(function (
      a: any,
      b: any
    ) {
      // @ts-ignore
      return new Date(a.StartTime) - new Date(b.StartTime);
    });
    const newAppointments = this.handleFilterAppointments({
      getall: this.showAllAppointment,
      appointments: appointments,
    });
    for (const appointment of newAppointments) {
      events.push({
        ...appointment,
        start: moment(appointment.StartTime).format("YYYY-MM-DD"),
        end: moment(appointment.EndTime).format("YYYY-MM-DD"),
        title: appointment.AppointmentID,
      });
    }
    return events;
  }

  get appoinmentWithDatePick() {
    const appointments = [];
    for (const appointment of this.coEvents) {
      if (
        moment(appointment.StartTime).format("YYYY-MM-DD") === this.datePick
      ) {
        const startTime = moment(appointment.StartTime);
        const endTime = moment(appointment.EndTime);
        const duration = moment.duration(endTime.diff(startTime));
        appointments.push({
          time: `${startTime.format("HH:mm")}`,
          endtime: `${endTime.format("HH:mm")}`,
          start: moment(appointment.StartTime).format("YYYY-MM-DD"),
          end: moment(appointment.EndTime).format("YYYY-MM-DD"),
          id: appointment.AppointmentID,
        });
      }
    }
    return appointments;
  }

  async mounted() {
    const vm = this as any;
    bindEvent(window, "message", this.windowListenHandler);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  async windowListenHandler(e: any) {
    const vm = this as any;
    if (e.data && typeof e.data === "string" && stringValidJson(e.data)) {
      const data = JSON.parse(e.data);
      if (data.type === "omnicasa-route") {
        // refresh date pick.
        vm.datePick = "";
      }

      if (data.type === "close") {
        vm.$destroy();
        removeEvent(window, "message", this.windowListenHandler);
      }
    }
  }

  beforeDestroy() {
    removeEvent(window, "message", this.windowListenHandler);
  }

  checkDateHasEvents(date: string) {
    const find = this.coEvents.find(
      (event) => event.start.includes(date) || event.end.includes(date)
    );
    return !!find ? true : false;
  }

  async handleDayClick(event: any) {
    if (this.currentView === "month") {
      const datepick = moment(event).format("YYYY-MM-DD");
      if (this.checkDateHasEvents(datepick)) {
        this.datePick = datepick;
      }
    }
  }

  handleViewChange(event: any) {
    this.currentView = event.view || "month";
  }

  backToCalendar() {
    this.datePick = "";
  }

  handleChooseAppointment(id: number) {
    const elLefts = document.getElementsByClassName("overlay-left");

    for (const left of elLefts) {
      left.setAttribute("style", "width: 100%");
      const elright = document.getElementsByClassName(
        "overlay-right-" + left.getAttribute("data-id")
      );
      elright[0].setAttribute("style", "width: 0; left: 100%");
    }

    const elLeft = document.getElementsByClassName("overlay-left-" + id);
    const elRight = document.getElementsByClassName("overlay-right-" + id);

    if (elLeft.length >= 1 && elRight.length >= 1) {
      elLeft[0].setAttribute("style", "width: 0%");
      elRight[0].setAttribute("style", "width: 100%; left: 0");
    }
  }

  async handleConfirmAppointment(id: number) {
    const validate = await this.checkAppointmentExists(id);
    if (!validate) {
      this.$alert(this.$t("APPOINTMENT_TAKEN") as string, "", {
        confirmButtonText: "OK",
        callback: async (action) => {
          this.backToCalendar();
          await this.getAppointmentByObjectId({
            objectId: this.getConfig.objectId,
            key: this.getConfig.key,
            IsProject: getBoolean(this.getConfig.isProject),
          });
        },
      });
    } else {
      this.syncAppointmentClick({ id: id });
      if (this.isProject) {
        this.$router.replace({
          name: "contact-appointment-project",
        });
      } else {
        this.$router.replace({
          name: "contact-appointment",
        });
      }
    }
  }

  handleFilterAppointments(payload: {
    getall: boolean;
    appointments: IAppointment[];
  }) {
    const { getall, appointments } = payload;
    const objectAppoinment: any[] = [];
    return appointments.filter((appointment: IAppointment, index: number) => {
      let same = 0;
      let startTimeBeforeItem;
      let ddStartTimeBeforeItem;
      let mmStartTimeBeforeItem;
      let yyyyStartTimeBeforeItem;
      objectAppoinment[index] = appointment;

      if (index > 0) {
        startTimeBeforeItem = new Date(objectAppoinment[index - 1].StartTime);
        ddStartTimeBeforeItem =
          (startTimeBeforeItem.getDate() < 10 ? "0" : "") +
          startTimeBeforeItem.getDate();
        mmStartTimeBeforeItem =
          (startTimeBeforeItem.getMonth() + 1 < 10 ? "0" : "") +
          (startTimeBeforeItem.getMonth() + 1);
        yyyyStartTimeBeforeItem = startTimeBeforeItem.getFullYear();
      }

      const startTime = new Date(appointment.StartTime);
      const ddStart =
        (startTime.getDate() < 10 ? "0" : "") + startTime.getDate();
      const mmStart =
        (startTime.getMonth() + 1 < 10 ? "0" : "") + (startTime.getMonth() + 1);
      const yyyyStart = startTime.getFullYear();
      if (
        index > 0 &&
        ddStartTimeBeforeItem === ddStart &&
        mmStartTimeBeforeItem === mmStart &&
        yyyyStartTimeBeforeItem === yyyyStart &&
        (getall
          ? objectAppoinment[index - 1].StartTime === appointment.StartTime
          : 1 == 1)
      ) {
        same = 1;
      }
      if (same === 0) {
        return appointment;
      }
    });
  }
}
